.slick-image.slide-principal.unimake-img {
	width: 100% !important;
	height: auto !important;
}

.principal-slider {
	padding-left: 0px !important;
	padding-right: 0px !important;
	width: 100% !important;
	height: 638px !important;
}

.product-card {
	width: 350px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.description-area {
	color: #000 !important;
}
