@import "../../assets/scss/Responsive.scss";

.ant-drawer-title {
	color: #fff;
}
.ant-drawer-header {
	border-bottom: 1px solid #fff;
}
.bar-menu {
	font-size: 17px;
}
.logo-image {
	width: 115px;
	margin-right: 50px;
}
.menu-unimake {
	position: fixed;
	z-index: 999;
	width: 85%;
	left: 0;
	padding-left: 30px;
}
.ant-menu-horizontal {
	border: none;
}

.menu-icone {
	border: none;
	color: #fff;
	padding: 0 15px;
}
.menu-icone:hover,
.menu-icone:active,
.menu-icone:focus {
	border: none;
	color: #fff;
}

.menu-unimake-login {
	line-height: 64px;
	text-align: right;
	position: fixed;
	z-index: 999;
	width: 15%;
	right: 0;
	padding-left: 30px;
	background: #034563dd;
}

.ant-popover-placement-bottomRight {
	padding-top: 25px !important;
}

.menu-username .ant-menu-item-group-title {
	color: #FFF !important;
	font-weight: bold;
}

.btn-sair {
	border: solid 1px;
	background: #034563dd;
	color: #FFF;
	text-align: left;
	width: 100%;
	padding-left: 8px;
}

//xs, sm e md
@media (max-width: 991px) {
	.ant-layout-header {
		padding: 0 15px;
	}
	.ant-menu {
		padding-left: 0px !important;
		background: none;
	}
	.ant-menu-horizontal {
		padding-left: 0px !important;
	}
	.menu-unimake {
		width: 256px;
	}
	.menu-unimake-login {
		line-height: 64px;
		width: 256px;
		left: 0;
		top: 270px;
		padding-left: 30px;
		text-align: left;
	}
	.welcome {
		font-size: 16px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.logo-sm {
		width: calc(100% - 40px);
		float: right;
		padding: 14px 0px;
	}
	.logo-sm > .logo-image {
		margin: 0 auto;
		display: block;
	}
	.ant-carousel .slick-dots {
		margin-left: 0;
		margin-right: 0;
	}
}

//lg
@media (min-width: 992px) {
	//
}
