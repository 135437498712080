.topo-produto {
	display: block;
	width: auto;
	height: 350px;
	padding: 20px;
	margin-top: auto;
	margin-bottom: auto;

	@media #{$mq-small} {
		height: 250px;
		margin: 0 auto;
		padding: 0px;
	}
}

.topo-border-rounded {
	padding-top: 25px;
	padding-bottom: 55px;
	border-bottom-left-radius: 50% 20%;
	border-bottom-right-radius: 50% 20%;
}
.topo-border-rounded h1 {
	color: #fff !important;
	margin-bottom: 0px !important;
	font-size: 40px;
	text-align: right;
}
.topo-border-rounded p,
.topo-border-rounded h2 {
	color: #fff !important;
	font-size: 35px;
	font-weight: 100;
	text-align: right;

	@media #{$mq-xsmall} {
		text-align: center;
	}
	@media #{$mq-small} {
		text-align: center;
	}
	@media #{$mq-medium} {
		text-align: center;
	}
}
.topo-border-rounded h2 {
	margin-top: 0px;
	margin-bottom: 20px !important;
}
.topo-border-rounded p.subtitulo {
	text-align: right;
	font-size: 22px;

	@media #{$mq-xsmall} {
		text-align: center;
	}
	@media #{$mq-small} {
		text-align: center;
	}
	@media #{$mq-medium} {
		text-align: center;
	}
}

.color-auto-pecas,
.color-materiais-construcao,
.color-materiais-eletricos,
.color-ferragens {
	background-color: #238883;
}
.color-bi {
	background-color: #333;
}
.color-unidanfe {
	background-color: #2273ab;
}
.color-forca-vendas {
	background-color: #772a40ff;
}

.color-black-transparent {
	background-color: #00000066;
	color: #fff;
}

.content-area-product {
	margin-top: 50px;
}
.pre-description-center {
	text-align: center;
	color: #000;
}
.pre-description-center > p {
	font-size: 27px;
	font-weight: 700;
}

.footerImageShape {
	height: "auto";
	width: 100%;
	margin-bottom: 20px;
}

.autoPecasBanner {
	padding: 20px 0;
	margin-top: 20px;
	margin-bottom: 20px;
}
.autoPecasBanner-container {
	padding-left: 30px;
	padding-right: 30px;
}

// BI Page
.infoMiddle-bi {
	margin-right: 35px;
}

// Forca Vendas
.infoMiddle-fv {
	background-image: linear-gradient(#3498db, #2980b9);
	height: 450px;
	margin-top: 50px;
	margin-bottom: 50px;
}

.infoImageBGLeft-fv {
	padding-bottom: 30px;
	padding-top: 30px;
	margin-top: 50px;
	margin-bottom: 50px;
}

// Unidanfe
.imageMiddle-unidanfe {
	margin-top: 50px;
	margin-bottom: 50px;
}

.comoFunciona-unidanfe {
	margin-top: 50px;
	margin-bottom: 50px;
}

// Unico Page

.produto-content-header {
	padding-top: 2%;
}

.product-title {
	font-size: 22px;
}

.product-content {
	font-size: 18px;
}

.product-tag {
	font-size: 28px;
	padding-bottom: 10px;
	padding-top: 10px;
	margin-bottom: 5px;
	margin-top: 5px;
}

// Unico Fecularia Page

.imageMiddle-fec {
	max-width: 80%;
	margin-left: auto;
	margin-right: auto;
	display: block !important;
	margin-bottom: 40px;
	margin-top: 40px;
}

.headerFeaturesa-fec {
	margin-bottom: 20px;
	margin-top: 20px;
	font-size: 29px !important;
	text-align: center;
	color: #208681 !important;
	text-transform: uppercase;
	font-weight: bold;
}

.FeaturesAboveImage-fec {
	line-height: 2.5em;
	font-size: 18px;
}

.featureTitleIndicator {
	font-size: 19px;
	color: white;
	margin-right: 5px;
	font-weight: bold;
	padding: 1px 8px 2px 8px;
	border-radius: 5px;
}

.featureTitle-fec {
	text-transform: uppercase;
	font-size: 18px;
	font-weight: bold;
	color: #208681;
}

.featuresContent-fec {
	padding-left: 18px;
}

.agroBanner-fec {
	margin-top: 20px;
	margin-bottom: 20px;
	background-image: linear-gradient(#3498db, #2980b9);
}

.agroBanner-container-fec {
	padding-left: 30px;
	padding-right: 30px;
}

.imageLeft-fec {
	max-width: 90%;
}

.imageLeft-Text-fec {
	margin-bottom: auto;
	color: #fff;
	font-weight: bold;

	@media #{$mq-small} {
		font-size: 4vh;
	}

	@media #{$mq-medium} {
		font-size: 4vh;
	}

	@media #{$mq-large} {
		font-size: 5vh;
	}

	@media #{$mq-xlarge} {
		font-size: 5vh;
	}
}

.imagemodulos-fec {
	margin-left: auto;
	margin-right: auto;
	display: block;
	height: 600px;
	width: 1000px;
}

.construcao-middle-row {
	padding: 20px 20px 20px !important;
}

// Unico Material Eletrico Page

.textContatoPageProduto {
	display: block;
	color: #000;
	margin-left: auto;
	margin-right: auto;
	padding-top: 30px;
	font-size: 42px;
	font-weight: 300;
	text-align: center;
}

.buttonContato {
	margin-top: 25px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	font-size: 29px;
	height: 60px;
	width: 230px;
	border-radius: 35px;
	background-color: #1b262c;
	border-color: #141b1f;
}

.buttonContato:hover {
	background-color: #2b2f31;
	border-color: #131e24;
}

.buttonContato span {
	margin-top: 12px;
}

.footer-product-curve {
	padding: 20px;
	width: 100%;
	height: 35vh;
	margin-bottom: 20px;
}
