@import "./../../assets/scss/Responsive.scss";

.about {
	height: auto !important;
}

.img-titulo {
	width: 100%;
	margin-top: 25px;
}

h2 {
	margin: 30px 0 0 0;
}

.titulo-oculto {
	opacity: 0;
}
