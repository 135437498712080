@import "./assets/scss/Colors.scss";
@import "./assets/scss/Main.scss";
@import "./assets/scss/Responsive.scss";
@import "./assets/scss/AcmeTemplate.scss";
@import "./assets/scss/Produtos.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  scrollbar-color: dark;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
  height: 100%;
  font-size: $base-font-size !important;
  line-height: $base-line-height !important;

  @media #{$mq-medium} {
    font-size: $base-font-size * 1.2;
    line-height: $base-line-height * 1.2;
  }

  @media #{$mq-large} {
    font-size: $base-font-size * 1.3;
  }

  @media #{$mq-xlarge} {
    font-size: $base-font-size * 1.4;
  }
}

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  color: #333 !important;
  /*font-family: 'Open Sans', sans-serif;*/
}

.ant-layout {
  //TODO: Backup background #f0f2f5
  background: #e3e5e7;
}

.unimake-margin {
  @media #{$mq-medium} {
    margin-bottom: 55px !important;
    margin-top: 55px !important;
  }

  @media #{$mq-large} {
    margin-bottom: 55px !important;
    margin-top: 55px !important;
  }

  @media #{$mq-xlarge} {
    margin-bottom: 55px !important;
    margin-top: 55px !important;
  }
}

.unimake-padding {
  padding-bottom: 15vh !important;
  padding-top: 15vh !important;

  @media #{$mq-xsmall} {
    padding-bottom: 15vh !important;
    padding-top: 15vh !important;
  }

  @media #{$mq-small} {
    padding-bottom: 15vh !important;
    padding-top: 15vh !important;
  }

  @media #{$mq-medium} {
    padding-bottom: 20vh !important;
    padding-top: 20vh !important;
  }

  @media #{$mq-large} {
    padding-bottom: 20vh !important;
    padding-top: 20vh !important;
  }

  @media #{$mq-xlarge} {
    padding-bottom: 20vh !important;
    padding-top: 20vh !important;
  }
}

.unimake-center-vertically {
  top: 50% !important;
}

.responsive-table {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.unimake-green-button {
  background-color: #246b00 !important;
  color: white !important;
  border: 1px solid #246b00 !important;
  font-weight: 450 !important;
}

.unimake-red-button {
  background-color: #d32323 !important;
  color: white !important;
  border: 1px solid #d32323 !important;
  font-weight: 450 !important;
}

.unimake-minor-button {
  font-size: 11px !important;
}

.header-title {
  font-weight: 400;
  font-size: 22px;
}

.bellow-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.288);
  margin-bottom: 10px;
}

.list-without-bullet {
  list-style-type: none;
}

.react-grid-Grid {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.umk-form-field {
  margin-left: 5px;
  margin-right: 5px;
}

.ant-form-item-explain ant-form-item-explain-error {
  margin-top: 2px !important;
}
