@import "../Responsive.scss";

// Tamanhos
$width-produto: 130px;

/* COLORS */
$primary-color: #034563;
$primary-dark: #060709;
$hover-color: #0f8bd3;
$divider-color: #1a1c28;

/* UNIMAKE DARK PALLET https://colorhunt.co/palette/167893 */
$dk-unimake-primary: #fff;
$dk-unimake-secondary: #f5f5f5;
$dk-unimake-darkblue: #0f4c75;
$dk-unimake-blue: #3282b8;
$dk-unimake-verylightblue: #bbe1fa;

$umk-font-color: #212121;

$esmerald: #2ecc71;

.react-grid-HeaderCell {
	background: $dk-unimake-primary !important;
	border-right: 1px solid $dk-unimake-secondary !important;
	border-bottom: 1px solid $dk-unimake-secondary !important;
}

.react-grid-Cell {
	background-color: $dk-unimake-secondary !important;
	padding-left: 8px;
	padding-right: 8px;
	border-right: 1px solid #eee;
	border-bottom: 1px solid #dddddd;
}

.principal-slider {
	padding-left: 0px !important;
	padding-right: 0px !important;
	width: 100% !important;
	height: 638px !important;
}

/*footer*/
.footer {
	// height: 400px;
	background: $primary-color;
	color: #fff;
	padding: 10px 0;
	display: inline-block;
	width: 100%;
	font-weight: 300;
}
.footer h1 {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 400;
	margin-top: 0;
	margin-bottom: 20px;
}
.footer address a {
	color: #fff;
}

.footer-container {
	padding: 10px 10px 10px 10px;
	margin: 10px 10px 10px 10px;
}

.copyright-anchor-v3 {
	color: #ccc !important;
}

.default-text-onblack {
	color: #49475c;
}

ul.page-footer-list-v3 {
	list-style: none;
	padding: 0px 10px;
}
ul.page-footer-list-v3 li {
	line-height: 30px;
}
ul.page-footer-list-v3 a {
	color: #fff;
	font-weight: 200;
}
ul.page-footer-list-v3 li i {
	padding-right: 10px;
}

// RainbowLine
.rainbow-line {
	padding-top: 3px;
	box-shadow: 12px 0 15px -4px rgba(31, 73, 125, 0.8), -12px 0 8px -4px rgba(31, 73, 125, 0.8);
	height: 3px;
	background-color: red; /* For browsers that do not support gradients */
	background-image: linear-gradient(to left, #33326a, #48519f, #569ad9, #9ebe5d, #f1c40f, #e67e22, #e74c3c);
}

.social-bar {
	margin-top: 10px;
}

.slick-image {
	height: inherit !important;
	width: 100% !important;
}

.ant-carousel .slick-slide {
	padding-top: 0.2vh;
	text-align: center;
	height: 74vh !important;
	line-height: 160px;
	background: #364d79;
	overflow: hidden;
	box-shadow: 5px 5px #000;

	@media #{$mq-xsmall} {
		height: auto !important;
	}

	@media #{$mq-small} {
		height: auto !important;
	}
}

.ant-carousel .slick-list {
	box-shadow: 0px 2px 15px 1px black;
}

.ant-carousel .slick-slide h3 {
	color: #000;
}

.product-home-areav3 {
	padding-top: 55px;
	background-color: $dk-unimake-secondary;
}

.unimake-area-v3 {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: $dk-unimake-secondary;
}

.ant-radio-button-wrapper-checked {
	background-color: $dk-unimake-darkblue !important;
	border-color: $dk-unimake-darkblue !important;
}

.ant-radio-button-wrapper:hover {
	background-color: $dk-unimake-darkblue !important;
	border-color: $dk-unimake-darkblue !important;
	color: #fff;
}

.card-produto-v3 {
	position: relative;
	// background-color: $dk-unimake-primary;
	width: $width-produto;
	margin-top: 10px;
	margin-bottom: 10px;
}

.card-produto-background-v3 {
	width: $width-produto;
}

.card-produto-footer-v3 .produto-saiba-mais:hover {
	background-color: #ffa940 !important;
}
.card-produto-footer-v3 .produto-download:hover {
	background-color: #0c6b97 !important;
}
.card-produto-footer-v3 .produto-comprar:hover {
	background-color: #359e00 !important;
}

.card-produto-box-title-v3 {
	padding-bottom: 1px;
}

.card-produto-title-v3 {
	color: #000;
	margin-left: 10px;
	margin-right: 10px;
	font-size: 20px;
	text-shadow: 3px 3px 3px #000000;
	text-align: justify;
}

.card-produto-description-v3 {
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 15px;
	color: #000;
	font-size: 13px;
	text-shadow: -1px 0 #0303034f, 0 1px #0303034f, 1px 0 #0303034f, 0 -1px #0303034f;
}
.image-container > .titulo-produto {
	width: $width-produto;
	height: 45px;
	color: #333;
	font-size: 14px;
}

.image-container > .titulo-produto > span {
	vertical-align: bottom;
	text-align: center;
	display: table-cell;
	height: 45px;
	width: $width-produto;
}

.card-produto-footer-v3 {
	margin: 10px 10px 10px 10px;
}

.card-produto-footer-preco-v3 {
	color: #e6e6e6;
}

.slider-noticias-v3 {
	margin-left: 20px;
	margin-right: 20px;
}

.unimake-slide-wrapper {
	display: flex !important;
	justify-content: center;
	height: 74vh;
	align-items: center;
	overflow: hidden;

	@media #{$mq-xsmall} {
		height: auto;
	}

	@media #{$mq-small} {
		height: auto;
	}
}

.blogpostimg {
	display: block;
	margin-left: auto;
	margin-right: auto;
	height: 199px;
	width: 263px;
}

.indicator-produto-slider {
	@media only screen and (max-width: 768px) {
		display: none;
	}
}

.umk-footer-quemsomos {
	color: #bb1344 !important;
	font-size: 18px;
}

.umk-footer-titulo {
	font-size: 18px;
	font-weight: bold;
}

.react-grid-Canvas {
	overflow: hidden !important;
}

.react-grid-Header {
	background: #1b262c !important;
}

.modal-faturamento {
	width: 55vw;

	@media only screen and (max-width: 768px) {
		width: 75vw;
	}
}
