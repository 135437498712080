.umk-slider-img {
	/* filter: url(filters.svg#grayscale); Firefox 3.5+ */
	filter: gray; /* IE5+ */
	-webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
	-webkit-transition: all 0.8s ease-in-out;
}

.umk-slider-img:hover {
	filter: none;
	-webkit-filter: grayscale(0);
	-webkit-transform: scale(1.01);
}

.unimake-slide-component .slick-initialized .slick-slide {
	float: none;
	display: inline-block;
	vertical-align: middle;
}

.unimake-slide-component .slick-initialized .slick-track {
	display: flex;
	align-items: center;
}

.unimake-slide-component .slick-slide {
	margin: 0px 25px;
}

.unimake-slide-component .slick-slide img {
	width: 80%;
	position: relative;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: auto !important;
	margin-bottom: auto !important;
	display: block;
}

.unimake-slide-component .slick-slider {
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.unimake-slide-component .slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}
.unimake-slide-component .slick-list:focus {
	outline: none;
}
.unimake-slide-component .slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.unimake-slide-component .slick-slider .slick-track,
.unimake-slide-component .slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.unimake-slide-component .slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	height: auto;
	vertical-align: middle;
}
.unimake-slide-component .slick-track:before,
.unimake-slide-component .slick-track:after {
	display: table;
	content: "";
}
.unimake-slide-component .slick-track:after {
	clear: both;
}
.unimake-slide-component .slick-loading .slick-track {
	visibility: hidden;
}

.unimake-slide-component .slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}
[dir="rtl"] .unimake-slide-component .slick-slide {
	float: right;
}
.unimake-slide-component .slick-slide img {
	display: block;
}
.unimake-slide-component .slick-slide.slick-loading img {
	display: none;
}
.unimake-slide-component .slick-slide.dragging img {
	pointer-events: none;
}
.unimake-slide-component .slick-initialized .slick-slide {
	display: block;
}
.unimake-slide-component .slick-loading .slick-slide {
	visibility: hidden;
}
.unimake-slide-component .slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}
.unimake-slide-component .slick-arrow.slick-hidden {
	display: none;
}
